import Confirm from '../components/Confirm';

    const ConfirmEmail = (): JSX.Element => {
  return (
    <div id='confirm-email'>
      <Confirm />
    </div>
  );
};

export default ConfirmEmail;
