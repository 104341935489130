import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const ConfirmEmail = (): JSX.Element => {
  const theme = useTheme();

  return (
    <div id='confirm-email'>
      <Box
        sx={{
          pt: 5,
          pb: 12,
          px: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box marginBottom={4}>
          <Typography
            variant='h5'
            align='center'
            color={theme.palette.text.primary}
            fontWeight={700}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              mb: 2,
            }}
          >
            Confirma tu correo
          </Typography>
          <Typography
            variant='subtitle1'
            align='center'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            Por favor, revisa tu buzón de correo electrónico y busca nuestro correo para confirmar tu email.
            <br />
            Si no encuentras el correo electrónico, asegúrate de revisar la carpeta de correo no deseado.

          </Typography>
        </Box>
        <Container>
          <Grid container spacing={4}>
            {/* You can add additional static content here if needed */}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default ConfirmEmail;