import Tos from '../components/Tos';

    const TermsAndConditions = (): JSX.Element => {
  return (
    <div id='tos'>
      <Tos />
    </div>
  );
};

export default TermsAndConditions;
