import React, { useEffect }  from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const PrivacyPolicy = (): JSX.Element => {
  const theme = useTheme();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id='privacy-policy'>
      <Box
        sx={{
          pt: 5,
          pb: 12,
          px: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box marginBottom={4}>
          <Typography
            variant='h5'
            align='center'
            color={theme.palette.text.primary}
            fontWeight={700}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              mb: 2,
            }}
          >
            Política de Privacidad
          </Typography>
          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            Con Potencial, ("nosotros", "nuestro" o "nosotros") está
            comprometido a proteger la privacidad y seguridad de tu información
            personal. Esta Política de Privacidad describe cómo recopilamos,
            usamos y divulgamos tu información personal cuando utilizas nuestro
            sitio web, productos o servicios.
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>1. Información que Recopilamos</strong>
            <br />
            Podemos recopilar los siguientes tipos de información personal:
            <br />
            1.1. Información Personal que Proporcionas: Cuando utilizas nuestro sitio web, productos o servicios, puedes proporcionarnos información personal como tu nombre, dirección de correo electrónico, dirección postal, número de teléfono y otros datos de contacto.
            <br />
            1.2. Información Recopilada Automáticamente: Podemos recopilar automáticamente información sobre tu dispositivo, incluida tu dirección IP, tipo de navegador, sistema operativo y otros detalles técnicos.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>2. Cómo Usamos tu Información</strong>
            <br />
            Usamos tu información personal para los siguientes fines:
            <br />
            2.1. Para proporcionar y mantener nuestros productos y servicios.
            <br />
            2.2. Para responder a tus consultas, comentarios o solicitudes.
            <br />
            2.3. Para enviarte boletines, comunicaciones de marketing y materiales promocionales si has optado por recibirlos.
            <br />
            2.4. Para mejorar nuestro sitio web, productos y servicios.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>3. Cómo Compartimos tu Información</strong>
            <br />
            Podemos compartir tu información personal con terceros en las siguientes circunstancias:
            <br />
            3.1. Con tu consentimiento.
            <br />
            3.2. Para cumplir con obligaciones legales.
            <br />
            3.3. Para proteger y defender nuestros derechos y propiedades.
            <br />
            3.4. En relación con una transacción comercial, como una fusión, adquisición o venta de activos.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>4. Tus Opciones</strong>
            <br />
            Tienes las siguientes opciones con respecto a tu información personal:
            <br />
            4.1. Puedes actualizar, corregir o eliminar tu información personal poniéndote en contacto con nosotros.
            <br />
            4.2. Puedes optar por no recibir comunicaciones de marketing.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>5. Seguridad</strong>
            <br />
            Tomamos medidas razonables para proteger tu información personal contra accesos o divulgaciones no autorizadas.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>6. Cambios en esta Política de Privacidad</strong>
            <br />
            Podemos actualizar esta Política de Privacidad de vez en cuando. Te notificaremos cualquier cambio publicando la nueva Política de Privacidad en esta página.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>7. Contáctanos</strong>
            <br />
            Si tienes alguna pregunta o inquietud acerca de esta Política de Privacidad, contáctanos en ayuda@conpotencial.com.
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            Esta Política de Privacidad es efectiva a partir del{' '}
            <strong>15 Junio 2023</strong>.
          </Typography>
        </Box>
        <Container>
          <Grid container spacing={4}>
            {/* You can add additional static content here if needed */}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default PrivacyPolicy;