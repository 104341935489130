import React, { useEffect }  from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';

const TermsAndConditions = (): JSX.Element => {
  const theme = useTheme();

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id='tos'>
      <Box
        sx={{
          pt: 5,
          pb: 12,
          px: 2,
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box marginBottom={4}>
          <Typography
            variant='h5'
            align='center'
            color={theme.palette.text.primary}
            fontWeight={700}
            marginTop={theme.spacing(1)}
            gutterBottom
            sx={{
              textTransform: 'uppercase',
              mb: 2,
            }}
          >
            Términos y Condiciones
          </Typography>
          <Typography
            variant='subtitle1'
            align='center'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            Última Actualización: <strong>1 Junio 2023</strong>
          </Typography>
          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            Estos Términos y Condiciones ("Acuerdo") constituyen un acuerdo legalmente vinculante entre usted ("Usuario" o "usted") y Con Potencial ("nosotros," "nos" o "nuestro"). Al acceder o utilizar nuestro servicio, usted acepta quedar vinculado por estos términos. Si no está de acuerdo con alguna parte de estos términos, no podrá acceder al servicio.
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>1. Descripción del Servicio</strong>
            <br />
            Con Potencial proporciona un servicio que permite a los usuarios registrados acceder y ver informes de investigación de mercado relacionados con ventas en plataformas de comercio electrónico en línea en nuestro sitio web.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>2. Registro de Usuario</strong>
            <br />
            2.1. Para acceder y utilizar el servicio, debe crear una cuenta proporcionando información precisa y completa.
            <br />
            2.2. Usted es responsable de mantener la confidencialidad de sus credenciales de cuenta y de todas las actividades que ocurran bajo su cuenta.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>3. Uso del Servicio</strong>
            <br />
            3.1. Usted acepta utilizar el servicio únicamente con el propósito de acceder y ver informes de investigación de mercado.
            <br />
            3.2. No puede intentar acceder a áreas del servicio o información no destinadas para usted.
            <br />
            3.3. Usted es responsable de todo el contenido publicado y la actividad que ocurra bajo su cuenta.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>4. Propiedad Intelectual</strong>
            <br />
            4.1. Todos los informes de investigación de mercado y contenido proporcionados a través del servicio son propiedad intelectual de Con Potencial y están protegidos por leyes de derechos de autor y otras leyes de propiedad intelectual.
            <br />
            4.2. Solo puede usar los informes para sus propios fines comerciales internos y no puede reproducir, distribuir ni utilizarlos de ninguna otra manera con fines comerciales.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>5. Conducta del Usuario</strong>
            <br />
            5.1. Usted acepta no participar en ninguna actividad que pueda interferir o interrumpir el servicio.
            <br />
            5.2. No puede utilizar el servicio con fines ilegales o no autorizados.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>6. Terminación</strong>
            <br />
            6.1. Nos reservamos el derecho de suspender o dar por terminada su cuenta a nuestra sola discreción si creemos que ha violado estos términos.
            <br />
            6.2. Usted puede dar por terminada su cuenta en cualquier momento comunicándose con el soporte al cliente.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>7. Privacidad</strong>
            <br />
            7.1. Su uso del servicio está sujeto a nuestra Política de Privacidad.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>8. Renuncias</strong>
            <br />
            8.1. Los informes de investigación proporcionados son solo con fines informativos y no deben considerarse asesoramiento profesional.
            <br />
            8.2. No ofrecemos garantías ni representaciones sobre la precisión o completitud de los informes.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>9. Limitación de Responsabilidad</strong>
            <br />
            9.1. Con Potencial no será responsable de daños indirectos, incidentales, especiales, consecuentes o punitivos.
            <br />
          </Typography>

          <Typography
            variant='subtitle1'
            color={theme.palette.text.secondary}
            marginTop={theme.spacing(1)}
            gutterBottom
          >
            <strong>10. Ley Aplicable</strong>
            <br />
            10.1. Estos términos y condiciones se rigen por y se interpretan de acuerdo con las leyes de México.
          </Typography>
        </Box>
        <Container>
          <Grid container spacing={4}>
            {/* You can add additional static content here if needed */}
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default TermsAndConditions;
