import Privacy from '../components/Privacy';

    const PrivacyPolicy = (): JSX.Element => {
  return (
    <div id='privacy-policy'>
      <Privacy />
    </div>
  );
};

export default PrivacyPolicy;
