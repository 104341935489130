import { useState, useEffect, useMemo } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ReactGA from 'react-ga4';

import getTheme from './theme/theme';
import ColorModeContext from './utils/ColorModeContext';
import Layout from './layout/Layout';
import Home from './pages/Home';
import ConfirmEmail from './pages/ConfirmEmail';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import LandingPageMeli from './pages/LandingPageMeli';


const NotFound = () => {
  return (
    <div style={{ textAlign: 'center', marginTop: '20vh' }}>
      <h3>404 - Lo sentimos. La página que buscas no se encuentra disponible.</h3>
    </div>
    );
};


const App = (): JSX.Element => {
  const [mode, setMode] = useState('light');
  const colorMode = useMemo(
    () => ({
      // The theme mode switch will invoke this method
      toggleColorMode: () => {
        window.localStorage.setItem(
          'themeMode',
          mode === 'dark' ? 'light' : 'dark'
        );
        setMode((prevMode) => (prevMode === 'dark' ? 'light' : 'dark'));
      },
    }),
    [mode]
  );

  useEffect(() => {
    try {
      const localTheme = window.localStorage.getItem('themeMode');
      localTheme ? setMode(localTheme) : setMode('light');
    } catch {
      setMode('light');
    }

    ReactGA.initialize('G-9K9MCR3QTM');
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search });

  }, []);

  return (
    <HelmetProvider>
      <ColorModeContext.Provider value={colorMode}>
        <ThemeProvider theme={getTheme(mode)}>
          <CssBaseline />
          <BrowserRouter>
            <Layout>
              <Routes>
                <Route
                  path='/'
                  element={
                    <>
                      <Helmet>
                        <title>Con Potencial | Identifica fácil y rápido productos con gran potencial de ventas y rentabilidad</title>
                      </Helmet>
                      <Home />
                    </>
                  }
                />
                <Route
                  path="/meli"
                  element={
                    <>
                      <Helmet>
                        <title>Identificador de Oportunidades en Mercado Libre | Con Potencial</title>
                      </Helmet>
                      <LandingPageMeli />
                    </>
                  }
                />
                <Route
                  path="/confirm-email"
                  element={
                    <>
                      <Helmet>
                        <title>Confirmar Correo | Con Potencial</title>
                      </Helmet>
                      <ConfirmEmail />
                    </>
                  }
                />
                <Route
                  path="/privacy-policy"
                  element={
                    <>
                      <Helmet>
                        <title>Política de Privacidad | Con Potencial</title>
                      </Helmet>
                      <PrivacyPolicy />
                    </>
                  }
                />
                <Route
                  path="/tos"
                  element={
                    <>
                      <Helmet>
                        <title>Términos y Condiciones | Con Potencial</title>
                      </Helmet>
                      <TermsAndConditions />
                    </>
                  }
                />
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Layout>
          </BrowserRouter>
        </ThemeProvider>
      </ColorModeContext.Provider>
    </HelmetProvider>
  );
};

export default App;
