import Meli from '../components/Meli';
import Pricing from '../components/Pricing';

const LandingPageMeli = (): JSX.Element => {

  return (
    <div id='meli'>
      <Meli />
      <Pricing />
    </div>
  );
};

export default LandingPageMeli;