import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useTheme } from '@mui/material/styles';

const Newsletter = (): JSX.Element => {
  const theme = useTheme();

  return (
    <div id='newsletter'>
      <Box
        sx={{
          pt: 5,
          pb: 10,
          px: 2,
          backgroundColor: theme.palette.background.paper,
        }}
      >
        <Container>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Typography
                variant='h5'
                align='center'
                fontWeight={700}
                marginTop={theme.spacing(1)}
                gutterBottom
                sx={{
                  color: theme.palette.text.primary,
                  textTransform: 'uppercase',
                }}
              >
                Oportunidades de la Semana
              </Typography>
              <Typography
                variant='subtitle1'
                align='center'
                color={theme.palette.text.secondary}
                marginTop={theme.spacing(1)}
                gutterBottom
              >
                Recibe gratis nuestro boletín donde cada semana revelamos productos con gran potencial de ventas y rentabilidad.
                <br />
              </Typography>
            </Grid>
            <Grid item xs={12} md={6} margin='auto'>
              <Link to='https://promociones.conpotencial.com/subscription/form'>
                <Button
                  variant='contained'
                  color='primary'
                  fullWidth
                  sx={{ marginTop: theme.spacing(2) }}
                >
                  Suscríbete
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </div>
  );
};

export default Newsletter;